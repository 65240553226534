<template>
  <v-card class="RootCardStyle" color="#1e1e1e" tile>
    <v-card-text class="yellowColorVariant--text maxWidthVariant">
      <div class="fontsize25px font-weight-bold">Privacy Policy</div>
      <div class="fontsize18px white--text font-weight-bold pt-5">
        Introduction:
      </div>
      <div class="fontsize14px text-justify">
        Welcome to QRJungle! We are committed to protecting your privacy and
        ensuring that your personal information is handled in a safe and
        responsible manner. This Privacy Policy outlines how we collect, use,
        and protect your information when you use our app and services.
      </div>
      <div class="fontsize18px white--text font-weight-bold pt-5">
        Information We Collect:
      </div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel
          v-for="(item, i) in CollectInformationArray"
          :key="i"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        How We Use Your Information:
      </div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel
          v-for="(item, i) in HowWeUseInformationArray"
          :key="i"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        Data Security:
      </div>
      <div class="fontsize14px text-justify">
        We implement a variety of security measures to maintain the safety of
        your personal information. All sensitive information, such as
        authentication data, is transmitted via secure technologies. We also
        ensure that our third-party service providers, such as Razorpay, adhere
        to strict security and privacy standards.
      </div>
      <div class="fontsize18px white--text font-weight-bold pt-3">
        Third-Party Services:
      </div>
      <div class="fontsize14px text-justify">
        QRJungle uses third-party services, including Razorpay for payment
        processing. These services are governed by their own privacy policies.
        We encourage you to review the privacy policies of these third-party
        services to understand their practices.
      </div>
      <div class="fontsize18px white--text font-weight-bold pt-3">
        You have the right to:
      </div>
      <v-expansion-panels class="mb-6 expansionalStyle">
        <v-expansion-panel v-for="(item, i) in YoursRightsArray" :key="i">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <div class="font-weight-bold">{{ item.Title }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-justify">{{
            item.description
          }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="fontsize18px white--text font-weight-bold">
        Changes to This Privacy Policy:
      </div>
      <div class="fontsize14px text-justify">
        QRJungle may update this Privacy Policy from time to time. You are
        advised to review this Privacy Policy periodically for any changes.
      </div>
      <div class="fontsize18px white--text font-weight-bold pt-3">
        Contact Us
      </div>
      <div class="fontsize14px text-justify">
        If you have any questions about this Privacy Policy or our data
        practices, please contact us at: please contact us at
        mobil80solutions.services@gmail.com.
      </div>
      <div class="pt-5 fontsize14px">
        By using QRJungle, you agree to the terms of this Privacy Policy.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    CollectInformationArray: [
      {
        Title: "Personal Information",
        description:
          "Email Address: We collect your email address when you create an account. This is used solely for authentication purposes through an OTP (One-Time Password) system and to link purchased QR codes to your account.",
      },
      {
        Title: "Payment Information",
        description:
          "Razorpay API: All payment transactions are processed through the Razorpay API. QRJungle does not store or handle any payment information directly. For details on how your payment information is handled, please refer to Razorpay’s Privacy Policy.",
      },
      {
        Title: "Usage Data",
        description:
          "Device Information: We collect information about the device you use to access our app, including IP address, device type, operating system, and app usage data. This helps us improve our services and provide a better user experience.",
      },
    ],
    HowWeUseInformationArray: [
      {
        Title: "Authentication",
        description:
          "Your email address is used to authenticate your account via OTP and ensure secure access to your purchased QR codes.",
      },
      {
        Title: "Service Provision",
        description:
          "We use your email address to link and manage your custom QR codes.",
      },
      {
        Title: "Customer Support",
        description:
          "If you contact our support team, we may use your information to help resolve any issues you might be experiencing.",
      },
    ],
    YoursRightsArray: [
      {
        Title: "Access",
        description:
          "Request access to the personal information we hold about you.",
      },
      {
        Title: "Rectification",
        description:
          "Request correction of any inaccurate or incomplete information.",
      },
      {
        Title: "Deletion",
        description:
          "Request deletion of your personal information, subject to certain legal obligations.",
      },
    ],
  }),
};
</script>

<style>
.RootCardStyle {
  background-size: cover !important;
}
.maxWidthVariant {
  padding: 50px !important;
  /* padding-right: 80px !important; */
  /* max-width: 80% !important; */
}
.expansionalStyle.theme--light.v-expansion-panels .v-expansion-panel {
  margin: 2px !important;
  border: 2px solid #fdcb0a !important;
}
</style>
